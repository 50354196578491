@import "vars";

.re-form {
  .input,
  input {
    border-radius: 25px;
    background-color: #edf2f7;
    width: 100%;
    padding: 12px 25px;
    font-size: 18px;
    color: #6f6f6f;
    outline: none;
    height: auto;
    background-image: none !important;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }
}

.rounded-corners {
  border-radius: 25px;
}

.rounded-corners-lg {
  border-radius: 40px;
}

.re-button,
.re-button-sm {
  @extend .rounded-corners;
  border: 2px solid transparent;
  background-color: $red;
  color: white;
  fill: white;
  font-size: 18px;
  cursor: pointer;
  outline: none !important;

  &[disabled] {
    background-color: gray;
    cursor: not-allowed;
  }

  &:hover:not([disabled]) {
    border-color: #c63d4a;
  }

  &:focus,
  &.active {
    border-color: #7f000e;
  }
}

.re-button {
  padding: 12px 30px;
  font-weight: bold;
  text-align: center;
}

.re-button-sm {
  padding: 4px 20px;
  font-weight: 400;
}


@media screen and (max-width: 1119px) {
  .re-button-sm {
    padding: 3px 15px;
  }
}

@media screen and (max-width: 767px) {
  .re-button-sm {
    padding: 2px 10px;
  }
}

.re-button-xs {
  padding: 2px 15px;
  font-weight: 300;
}

.re-button.gray {
  background-color: $gray-1;

  &:hover:not([disabled]) {
    border-color: #959595;
  }

  &:focus,
  &.active {
    border-color: #474747;
  }
}

.re-button.yellow {
  background-color: $yellow;
  color: black;

  &:hover:not([disabled]) {
    border-color: #dbc865;
  }

  &:focus,
  &.active {
    border-color: #d2c84f;
  }
}

.re-button.green {
  background-color: $green;
  color: white;

  &:hover:not([disabled]) {
    border-color: $green-light;
  }

  &:focus,
  &.active {
    border-color: $green-light;
  }
}

.re-button.white {
  background-color: white;
  color: black;
  border: 1px solid #e2e8f0;

  &:hover:not([disabled]) {
    border-color: #d5d5d5;
  }

  &:focus,
  &.active {
    border-color: #bdbdbd;
  }
}

.caret-owner {
  position: relative;
}

.dropdown-toggle {
  @extend .caret-owner;

  app-caret svg {
    transition: transform .5s;
  }

  &::after {
    display: none !important;
  }

  &.active {
    app-caret svg {
      transform: scaleY(-1);
    }
  }
}


re-locality-search-input .dropdown-menu {
  max-height: 50vh;
  overflow-y: auto;
}
