//@import "node_modules/bootstrap/scss/bootstrap-grid";
//@import "node_modules/bootstrap/scss/bootstrap-reboot";
//@import "node_modules/bootstrap/scss/utilities/text";
////@import "node_modules/bootstrap/scss/utilities/shadows";
////@import "node_modules/bootstrap/scss/utilities/screenreaders";
//@import "node_modules/bootstrap/scss/dropdown";
//@import "node_modules/bootstrap/scss/forms";
////@import "node_modules/bootstrap/scss/custom-forms";
//@import "node_modules/bootstrap/scss/popover";

@import "node_modules/bootstrap/scss/bootstrap";

@import "styles/animatopy";
@import "styles/design";
@import "styles/forms";
@import "styles/fonts/font-source-sans-pro";
@import "styles/fonts/font-montserrat";

body {
  overflow-x: hidden;
  margin: 0;
  font-family: "Source Sans Pro", Roboto, "Helvetica Neue", sans-serif;
}

html.cdk-global-scrollblock body {
  overflow-x: visible;
}

svg-icon.icon {
  width: 1.25em;
  height: 1.25em;
  display: inline-block;

  svg {
    max-width: 100%;
    max-height: 100%;
  }

  &.icon-md {
    width: 2.2em;
    height: 2.2em;
  }

  &.icon-lg {
    width: 3em;
    height: 3em;
  }
}

.d-contents {
  display: contents;
}

.v-align-middle {
  vertical-align: middle;
}

.d-grid {
  display: grid;
}

.cursor-pointer {
  cursor: pointer;
}

//html, body {
//  height: 100%;
//}

.cdk-global-scrollblock {
  position: static;
  width: auto;
  overflow-y: auto;
}
