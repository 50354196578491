@import "vars";

.red {
  color: $red;
}

.green {
  color: $green;
  fill: $green;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semi-bold {
  font-weight: 500 !important;
}

.b-red {
  @extend .red;
  @extend .fw-bold;
}

.b-green {
  @extend .green;
  @extend .fw-bold;
}

.h1 {
  font-size: 36px;
  font-weight: 400;
}

.h2 {
  font-size: 24px;
  font-weight: 400;
}

.h3 {
  font-size: 18px;
  font-weight: 300;
}

.text-1 {
  font-size: 18px;
}

.text-feature {
  font-size: 18px;
}

@media screen and (max-width: 1119px) {
  .h1 {
    font-size: 24px;
  }
  .h2 {
    font-size: 28px;
  }
  .h3 {
    font-size: 16px;
  }
  .text-feature {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .h1 {
    font-size: 20px;
  }
  .h2 {
    font-size: 17px;
  }
  .h3 {
    font-size: 15px;
  }
  .text-feature {
    font-size: 14px;
  }
}

.card {
  @extend .shadow !optional; // todo: needs fixes?

  .header {
    @extend .h2;
    background-color: $yellow;
    padding: 24px;
  }

  .content {
    @extend .p-3;
    @extend .p-md-4;
    background-color: white;
  }

  .content.p-small {
    @extend .p-2;
    @extend .p-md-3;
  }

  .content.p-big {
    @extend .px-2;
    @extend .px-sm-3;
    @extend .px-md-4;
    @extend .px-lg-5;
  }
}


body {
  background-color: white;
}

.background {
  .bubbles {
    left: 0;
    width: 100%;
    bottom: 25%;
    z-index: -1;
  }

  .rectangle {
    width: 50%;
    top: 0;
    left: 0;
    transform: translate(-42%, -42%);
  }


  .bubbles,
  .rectangle,
  .ellipse {
    position: absolute;

    img {
      width: 100%;
    }
  }

  .ellipse {
    right: 0;
    width: 10%;
    top: 20%;
    overflow: hidden;

    img {
      width: 200%;
    }
  }

  .top-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 75%;
    background-color: #f3f3f3;
    z-index: -2;
  }
}

.custom-invisible {
  visibility: hidden;
  @extend .d-none;
  @extend .d-sm-block;
}

.background-green {
  background-color: $green !important;
  fill: $green;
  --background-color: #{$green};
  color: white;
}

.background-yellow {
  background-color: $yellow !important;
  fill: $yellow;
  --background-color: #{$yellow};
}

.fill-green {
  fill: $green !important;
}

.fill-white {
  fill: white !important;
}

.border-gray {
  border: 1px solid $gray-light;
}

.re-custom-dialog-panel {
  width: 500px;
  position: absolute !important;
  top: 200px !important;
}

@media screen and (max-width: 756px) {
  .re-custom-dialog-panel {
    width: auto;
  }
}
